.course-details {
  &__title {
    font-weight: 600;
    font-size: 34px;
    color: #222222;
    @include media-breakpoint-down(xl) {
      font-size: 1.9375rem;
    }
    @include media-breakpoint-down(lg) {
      font-size: 1.75rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 1.5rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 1.25rem;
    }
  }
  &__icons {
    justify-content: flex-end;
    @include media-breakpoint-down(lg) {
      justify-content: flex-start;
      padding: 0px;
    }

    ul {
      list-style: none;
      gap: 15px;
      li {
        border: 1px solid #bebebe;
        padding: 8px 12px;
        border-radius: 6px;

        i {
          color: #757575;
        }
      }
    }
  }
}

.property-detail {
  padding-top: 1.5rem;
  &__left {
    width: 12%;
  }
  &__right {
    width: 88%;
  }
  // .slide-indicator {
  //   #scroll::-webkit-scrollbar-track {
  //     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  //     background-color: #f5f5f5;
  //   }

  //   #scroll::-webkit-scrollbar {
  //     width: 10px;
  //     background-color: #f5f5f5;
  //   }

  //   #scroll::-webkit-scrollbar-thumb {
  //     background-color: #000000;
  //     border: 2px solid #555555;
  //   }
  // }
}
@media (max-width: 1200px) {
  .property-detail {
    &__left {
      width: 15%;
    }
    &__right {
      width: 85%;
    }
  }

}

@media (max-width: 992px) {
  .property-detail {
    &__left {
      width: 20%;
    }
    &__right {
      width: 80%;
    }
  }
  // .slide-indicator {
  //   max-height: 150px;
  //   overflow-y: scroll;
  //   gap: 0rem;
  //   #scroll::-webkit-scrollbar-track {
  //     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  //     background-color: #f5f5f5;
  //   }

  //   #scroll::-webkit-scrollbar {
  //     width: 10px;
  //     background-color: #f5f5f5;
  //   }

  //   #scroll::-webkit-scrollbar-thumb {
  //     background-color: #000000;
  //     border: 2px solid #555555;
  //   }
  // }
}

@media (max-width: 768px) {
  .property-detail {
    &__left {
      width: 100%;
    }
    &__right {
      width: 100%;
    }
    .property-detail {
      &__wrapper {
        flex-direction: column-reverse;
      }
    }
  }
  .slide-indicator {
    flex-direction: row;
    width: 100%;
    max-height: 300px;
    overflow-x: scroll;
    overflow-y: scroll;
    white-space: nowrap;
    #scroll::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }
    #scroll::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5;
    }
    #scroll::-webkit-scrollbar-thumb {
      background-color: #000000;
      border: 2px solid #555555;
    }
  }
}
@media (max-width: 576px) {
  .carousel-control-prev {
    left: -20px;
    img {
      width: 20px;
      height: auto;
    }
  }
  .carousel-control-next {
    right: 0px;
    img {
      width: 20px;
      height: auto;
    }
  }
}

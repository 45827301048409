.btn-warning {
  color: #fff;
}

.btn-primary {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  padding: 0.5rem 1.3rem;
  a{
    color: #fff;

  }
  &:hover{
    color: #fff;
    background-color: #FF5C00;
    border: 1px solid #FF5C00;
    transition: linear all;
  }

  @include media-breakpoint-down(xxl) {
    font-size: 14px;
  }
}

.btn__submit {
  position: absolute;
  top: 17%;
  right: 1%;
  border-radius: 30px;
  font-size: 16px;

  @include media-breakpoint-down(xl) {
    font-size: 14px;
  }

  @include media-breakpoint-down(sm) {
    position: relative;
    right: 0;
    top: 10px;
  }

  &::after {
    display: inline-block;
    content: "";
    vertical-align: -0.125em;
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z' clip-rule='evenodd'/></svg>");
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
  }
}

.btn-transparent {
  background: transparent;
  border: none;
}

.next8 {
  position: absolute;
  top: 48%;
  right: 1.3rem;
  z-index: 2;
}

.prev8 {
  position: absolute;
  top: 48%;
  left: 1.3rem;
  z-index: 2;
}

.next3 {
  position: absolute;
  top: 48%;
  right: 0.5rem;
  z-index: 2;
}

.prev3 {
  position: absolute;
  top: 48%;
  left: 0.5rem;
  z-index: 2;
}

.next1 {
  position: absolute;
  top: 48%;
  right: 0.5rem;
  z-index: 2;
}

.prev1 {
  position: absolute;
  top: 48%;
  left: 0.5rem;
  z-index: 2;
}

.hidden {
  display: none;
}

.our-team {
  &__title {
    font-weight: 600;
    font-size: 34px;
    color: #222222;
  }
  @media (max-width: 992px) {
    &__title {
      font-size: 26px;
      line-height: 32px;
    }
  }
  @media (max-width: 576px) {
    &__title {
      font-size: 22px;
      line-height: 28px;
    }
  }
}


.our-team-block {
  &__card {
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
  }
  &__card-body {
    padding: 15px 27px;
    min-height: 86px;
    h5 {
      font-size: 22px;
      line-height: 33px;
      color: #000000;
      @media (max-width: 1200px) {
        font-size: 21px;
      }

    }
    p {
      font-size: 16px;
      color: #808080;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
  }
}

.testimonials {
  padding: 1.5rem 0 2rem 0;
  h2 {
    margin-bottom: 2rem;
    @include media-breakpoint-down(lg) {
      margin-bottom: 1rem;
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 2rem 0rem;
  }
  position: relative;
  &__heading {
    position: relative;
    &::after {
      content: "";
      background-image: url(/static/assets/img/home/testimonials/ellipse.svg);
      background-repeat: no-repeat;
      content: "";
      display: inline-block;
      height: 230px;
      position: absolute;
      right: -90px;
      top: 25px;
      width: 230px;
      @include media-breakpoint-down(xl) {
        right: -65px;
      }
      @include media-breakpoint-down(lg) {
        right: 0px;
      }
    }
  }

  .card {
    background: #ffffff;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 1rem;
    @include media-breakpoint-down(sm) {
      padding: 2rem 0rem;
    }

    &::before {
      content: "";
      background-image: url("/static/assets/img/home/testimonials/quote-left.svg");
      background-repeat: no-repeat;
      width: 50px;
      height: 50px;
      top: -10px;
      left: 10px;
      position: absolute;
      display: inline-block;
    }

    &::after {
      content: "";
      background-image: url("/static/assets/img/home/testimonials/quote-right.svg");
      background-repeat: no-repeat;
      width: 50px;
      height: 50px;
      bottom: -35px;
      right: 10px;
      position: absolute;
      display: inline-block;
    }

    &-img {
      height: 70px;
      width: 70px;
    }

    &-title {
      display: flex;
      gap: 1rem;
      padding: 1rem 1rem 0;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }

      h4 {
        @include media-breakpoint-down(xxl) {
          font-size: 1rem;
        }
      }

      p {
        margin: 0;
        @include media-breakpoint-down(xxl) {
          font-size: 0.9rem;
        }
      }
    }

    &-text {
      font-size: 1rem;
      color: #757575;
      line-height: 28px;

      @include media-breakpoint-down(lg) {
        font-size: 0.9rem;
      }

      @include media-breakpoint-down(md) {
        font-size: 0.85rem;
      }

      @include media-breakpoint-down(sm) {
        font-size: 0.8rem;
      }
    }
  }

  .slick-list {
    padding: 1rem 14px;
  }

  .slick-slide {
    margin: 0 1.2rem;
  }

  .slick-dots li button::before {
    font-size: 10px;
  }

  .slick-dots li.slick-active button::before {
    color: #fd8901;
    font-size: 18px;
  }

  .image-control {
    height: 70px;
    width: 70px;
    border-radius: 50%;
  }
}

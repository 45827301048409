.countries {
  &__title {
    font-size: 2.125rem;
    font-weight: 600;
  }

  .row {
    @include media-breakpoint-down(lg) {
      --bs-gutter-y: 1rem;
      --bs-gutter-x: 2.5rem;
      margin-bottom: 0rem !important;
    }

    @include media-breakpoint-down(sm) {
      --bs-gutter-y: 0rem;
      --bs-gutter-x: 0rem;
    }
  }



  &__img {
    width: 100%;
    height:352px;
    border-radius: 13px;
    background-size: cover;
  }

  &__benchmarks {
    .card {
      border: none;
    }

    .location {
      position: absolute;
      left: 10%;
      bottom: 10%;
      color: #fff;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.1em;
    }
  }
}


.benchmark__card img {
  object-fit: cover;

  @include media-breakpoint-down(lg) {
    width: 100% !important;
    object-fit: cover;
  }
}



.overlay__countries {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 352px;
  border-radius: 13px;
  background: linear-gradient(23deg, rgba(2, 0, 36, 1) 0%, rgba(0, 212, 255, 0) 50%);


  // @include media-breakpoint-down(lg) {
  //   width: 206px;
  //   height: 90%;
  // }
  @media (min-width: 320px) and (max-width: 575px) {
    width: 100%;
    height: 352px;
  }
  @media (min-width: 576px) and (max-width: 768px) {
    width: 100%;
    height: 352px;
  }

  @media (min-width: 769px) and (max-width: 992px) {
    width: 100%;
    height: 352px;
  }

  @media (min-width: 993px) and (max-width: 1200px) {
    width: 100%;
    height: 352px;
  }
}

.test-prepare {
  &__title {
    font-size: 2.125rem;
  }
  h3 {
    font-size: 1.5rem;
    margin-top: 26px;
    font-weight: 600;
  }
  .row {
    @include media-breakpoint-down(xxl) {
      --bs-gutter-x: 3rem;
    }
    @include media-breakpoint-down(xl) {
      --bs-gutter-x: 3rem;
    }
    @include media-breakpoint-down(lg) {
      --bs-gutter-x: 3rem;
    }
    @include media-breakpoint-down(md) {
      --bs-gutter-x: 0rem;
    }

    .content {
      .how-it-works {
        h5 {
          font-size: 1rem;
          font-weight: 600;
        }
        &__icons {
          background-color: #fff7ed;
          border-radius: 12px;
          padding: 36px 51px;
          text-align: center;
          min-height: 145px;
          margin-bottom: 20px;
          @include media-breakpoint-down(xl) {
            min-height: 115px;
          }
          @include media-breakpoint-down(lg) {
            min-height: 140px;
          }
        }
      }
      .course-prices {
        padding-bottom: 2.625rem;
        @include media-breakpoint-down(xl) {
          min-height: 115px;
        }
        h5 {
          font-size: 1rem;
          font-weight: 600;
        }
        &__icons {
          background-color: #fff7ed;
          border-radius: 12px;
          padding: 36px 51px;
          text-align: center;
          min-height: 145px;
          margin-bottom: 20px;
        }
        &__card {
          background: #fff;
          box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
          border-radius: 6px;
          color: #0b4c8c;
          border: 2px solid #0b4c8c;
          &:hover {
            background: #0b4c8c;
            box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
            border-radius: 6px;
            color: #fff;
            .course-prices__card-support {
              color: #fff;
            }
            .course-prices__card-header {
              border-bottom: 1px solid #fff;
            }
            .course-prices__card-header::before {
              background: url("../../assets/img/icons/card-badge-white.svg")
              no-repeat;
            }
          }
        }
        &__card-header {
          font-size: 1.25rem;
          font-weight: 600;
          padding: 0.9375rem 2.5rem 0.9375rem;
          border-bottom: 0.2px solid #0b4c8c;
          position: relative;
          &::before {
            content: "";
            background: url("../../assets/img/icons/card-badge-blue.svg")
              no-repeat;
            position: absolute;
            top: 23px;
            left: 22px;
            width: 15px;
            height: 15px;
            display: inline-block;

          }
        }
        &__card-body {
          padding: 10px 26px 21px 26px;
        }
        &__card-title {
          font-size: 24px;
          line-height: 36px;
          font-weight: 700;
        }
        &__card-text {
          padding: 5px 0px 7px;
        }
        &__card-support {
          color: #808080;
        }
      }
    }
    .contact-form {
      background: #ffffff;
      box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      padding: 22px 26px 23px;
      .form-container {
        h5 {
          font-size: 1.25rem;
          font-weight: 600;
          padding-bottom: 1rem;
        }
        .row {
          margin-bottom: 1.25rem;
        }
        input[type="text"],
        input[type="email"] {
          background-color: #f3f3f3;
          padding: 12px 24px;
          border: 1px solid #e8e8e8;
          margin-top: 5px;
          &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #747575;
            opacity: 1; /* Firefox */
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #2c2c2c;
          }

          &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #2c2c2c;
          }

          @media (max-width: 1200px) {
            font-size: 0.8rem;
          }
        }

        textarea {
          background-color: #f3f3f3;
          padding: 12px 24px;
          border: 1px solid #e8e8e8;
          margin-top: 5px;
          &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #747575;
            opacity: 1; /* Firefox */
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #2c2c2c;
          }

          &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #2c2c2c;
          }

          @media (max-width: 1200px) {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}

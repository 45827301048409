.country-details {
  &__banner {
    position: relative;

    h3 {
      position: absolute;
      top: 35%;
      left: 25%;
      color: #fff;
      font-weight: 600;
      font-size: 4rem;
      line-height: 96px;
      letter-spacing: 0.25px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      z-index: 9;

      @include media-breakpoint-down(xl) {
        font-size: 3rem;
        top: 25%;
        left: 25%;
      }

      @include media-breakpoint-down(lg) {
        font-size: 2rem;
        top: 20%;
      }

      @include media-breakpoint-down(md) {
        font-size: 1.875rem;
        top: 10%;
        left: 20%;
      }

      @include media-breakpoint-down(sm) {
        font-size: 1.875rem;
        top: 0%;
        left: 5%;
      }
    }
  }

  &__desc {
    padding: 20px 0px 10px;

    blockquote {
      font-size: 22px;
      line-height: 33px;
      color: #757575;

      @include media-breakpoint-down(md) {
        font-size: 1.25rem;
        line-height: 26px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 1rem;
        line-height: 26px;
      }

    }
  }
}

.uni-list {
  h3 {
    font-size: 24px;

    @include media-breakpoint-down(sm) {
      font-size: 1.25rem;
      font-weight: 500;
    }



  }

  .row {
    @include media-breakpoint-down(lg) {
      --bs-gutter-y: 0rem;
      --bs-gutter-x: 1.25rem;
    }

    @include media-breakpoint-down(sm) {
      --bs-gutter-y: 0rem;
    }
  }
}

.uni-list__block {
  .card {
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    border: none;
  }

  .card-body {
    padding: 22px 15px 12px 15px;
  }

  .card-title {
    font-size: 1.375rem;
    line-height: 32px;

    @include media-breakpoint-down(lg) {}

    font-size: 1.125rem;
  }
}



.overlay-countries {
  position: absolute;
  z-index: 8 !important;
  border-radius: 0px;
  top: 0;
  bottom: 0;
  left: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  opacity: 0.3;
  display: block;
  transition: .5s ease;
  background-color: #000000;
}

.university__list-card {
  height: 150px;
  width: 100%;
  object-fit: fill;
}

.university__list-title-limit {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 65px;
}

.university__list-main-row {

  @include media-breakpoint-down(xxl) {
    column-gap: 2rem;
  }

  @include media-breakpoint-down(xl) {
    column-gap: 4rem;
  }

  @include media-breakpoint-down(lg) {
    column-gap: 4rem;
  }
}

.university__list-individual-card {
  @include media-breakpoint-down(sm) {
    margin: 0 auto;
  }
}

.title {
  &__primary {
    font-size: 3.125rem;
    line-height: 70px;
    margin-bottom: 0.5em;

    @include media-breakpoint-down(lg) {
      font-size: 2.5rem;
    }
  }

  &__secondary {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #ff5c00;
  }

  &__third {
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    text-transform: normal;
    color: #000000;
    @include media-breakpoint-down(xxl) {
      font-size: 28px;
      line-height: 36px;
    }
    @include media-breakpoint-down(xl) {
      font-size: 24px;
      line-height: 30px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 20px;
      position: relative;
      z-index: 2;
      line-height: 30px;
      margin-top: 12px!important;
    }
  }
}

.text__gray {
  color: #6c757d;

  @include media-breakpoint-down(xl) {
    font-size: 0.9rem;

  }

  @include media-breakpoint-down(lg) {
    font-size: 0.85rem;
    line-height: 24px;
  }
}

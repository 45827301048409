.max-width {
  &__hero-col {
    display: flex;
    flex-wrap: wrap;
    max-width: 460px;
    width: 100%;
    flex-direction: column;
  }
}

.margin-top-5 {
  margin-top: 5rem;

  @include media-breakpoint-down(lg) {
    margin: 0 1rem;
  }
}

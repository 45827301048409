@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

// customizing bootstrap colors
$primary: #0b4c8c;

$warning: #fd8901;

$white: #fff;

$accordion-bg: $primary;
$accordion-border-color: none;
$accordion-button-color: rgb(251, 251, 251);
$accordion-icon-color: #fff;
$accordion-icon-active-color: #fff;
$accordion-button-color: #fff;
$accordion-button-bg: $primary;
// $accordion-transition: $btn-transition, border-radius 0.15s ease;
$accordion-button-active-bg: $primary !important;

// IMPORTING THE SASS FOR BOOTSTRAP
@import "./node_modules/bootstrap/scss/bootstrap.scss";

.course-details {
  &__title {
    font-weight: 600;
    font-size: 34px;
    color: #222222;
    @include media-breakpoint-down(xl) {
      font-size: 1.9375rem;
    }
    @include media-breakpoint-down(lg) {
      font-size: 1.75rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 1.5rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 1.25rem;
    }
  }
  &__icons {
    justify-content: flex-end;
    @include media-breakpoint-down(lg) {
      justify-content: flex-start;
      padding: 0px;
    }

    ul {
      list-style: none;
      gap: 15px;
      li {
        border: 1px solid #bebebe;
        padding: 8px 12px;
        border-radius: 6px;

        i {
          color: #757575;
        }
      }
    }
  }
  // @media (max-width: 1200px) {
  //   &__title {
  //     font-size: 26px;
  //     line-height: 32px;
  //   }
  // }
  // @media (max-width: 576px) {
  //   &__title {
  //     font-size: 22px;
  //     line-height: 28px;
  //   }
  // }
}

.course-details-block {
  h3 {
    font-weight: 600;
  }
  h4 {
    font-weight: 600;
  }
}

.coursez {
  &__img-control {
    max-width: 1296px;
    max-height: 290px;
    height: 100%;
    width: 100%;
    border-radius: 12px;
    object-fit: cover;
  }
}

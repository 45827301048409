.career-plan {
  padding: 2rem;
  @include media-breakpoint-down(sm) {
    padding: 2rem 0rem;
  }

  &__heading {
    position: relative;
    &::after {
      content: "";
      background-image: url("/static/assets/img/home/career/cirle-pattern.png");
      background-repeat: no-repeat;
      content: "";
      display: inline-block;
      height: 230px;
      position: absolute;
      right: -50px;
      top: 0px;
      width: 230px;

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }

  &__card {
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    border: none;
  }

  &__img {
    padding: 1rem 1rem 0.8rem;
    img {
      width: 45px;
    }
  }

  &__top-row {
    @include media-breakpoint-down(lg) {
      --bs-gutter-y: 1rem;
    }
  }

  &__below-row {
    margin-top: 4rem;

    @include media-breakpoint-down(lg) {
      margin-top: 2rem;
      --bs-gutter-y: 1.5rem;
    }
  }
}

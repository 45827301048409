.red-btn {
  margin: 0.5rem 0;
}
.black-btn {
  margin: 0.5rem 0;
}

.red-btn label input {
  position: absolute;
  display: none;
  color: #fff !important;
}
.red-btn label input + span {
  padding: 0.5rem 1rem;
  color: #FD8901;
  cursor: pointer;

  @include media-breakpoint-down(xl) {
    font-size: 0.9rem;
  }

  @include media-breakpoint-down(lg) {
    font-size: 0.8rem;
  }
}

.red-btn input:checked + span {
  color: black;
  background-color: #fff;
}

.red-btn {
  &__hero {
    input:checked + span {
      color: #696b6e;
      background-color: #b4c0cd;
    }
  }
}

.black-btn label input {
  position: absolute;
  display: none;
  color: #fff !important;
}
.black-btn label input + span {
  padding: 0.5rem 1rem;
  color: $black;
  cursor: pointer;

  @include media-breakpoint-down(xl) {
    font-size: 0.9rem;
  }

  @include media-breakpoint-down(lg) {
    font-size: 0.8rem;
  }
}

.black-btn__hero label input + span {
  padding: 0.5rem 1rem;
  color: #696b6e;
  cursor: pointer;

  @include media-breakpoint-down(xl) {
    font-size: 0.9rem;
  }

  @include media-breakpoint-down(lg) {
    font-size: 0.8rem;
  }
}

.black-btn input:checked + span {
  color: #FD8901;

  background-color: #FFF4E6;
border: 1px solid #FD8901;
}

.black-btn {
  &__hero {
    input:checked + span {
      color: $primary;
      background-color: #fff;
    }
  }
}

.recommended {
  margin: 2rem 0;

  @include media-breakpoint-down(xl) {
    margin: 0.75rem 0;
  }
  @include media-breakpoint-down(lg) {
    margin: 0.3125rem 0;
  }
  .title__primary {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 32px;
    @include media-breakpoint-down(xl) {
      font-size: 1.5rem;
      line-height: 1.875rem;
      margin-bottom: 15px;
    }
  }

  &__btns-collection {
    display: flex;
    gap: 1.875rem;
    flex-wrap: wrap;

    @include media-breakpoint-down(xl) {
      overflow: auto;
      white-space: nowrap;
      padding-bottom: 10px;
      display: flex;
      gap: 10px;
      flex-wrap: nowrap;
    }

    button {
      margin-right: 30px;
      margin-top: 15px;

      @include media-breakpoint-down(lg) {
        margin-right: 15px;
        margin-bottom: 15px;
      }
    }
  }
}

.sidebar {
  //   BTNS COLLECTION
  &__btn-colllection {
    display: flex;
    flex-wrap: wrap;

    position: relative;
    @include media-breakpoint-down(xl) {
      gap: 1rem;
    }
    @include media-breakpoint-down(lg) {
      gap: 0.75rem;
    }
    @include media-breakpoint-down(sm) {
      gap: 0.75rem;
      padding-bottom: 1.25rem;
      overflow: auto;
      white-space: nowrap;
    }
  }

  &__btn-red {
    color: $primary;
    border: 1px solid #fd8901;
    border-radius: 10px;
    background-color: #fff4e6;

    &-hero {
      color: $primary;
      background-color: $white;
      border-radius: 6px;
    }
  }

  &__btn-black {
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f2f2f1;

    &-hero {
      color: #696b6e;
      background-color: #b4c0cd;
      border-radius: 6px;
    }
  }
}

.blogs {
  @include media-breakpoint-down(lg) {
    margin-top: 30px;
  }
  .card,
  .card--blog {
    box-shadow: 2px 3px 10px -1px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    border: none;
    .card__img {
      img {
        width: 100%;
        min-height: 315px;
        object-fit: cover;
        border-radius: 6px;
        overflow: hidden;
        @include media-breakpoint-down(xl) {
          min-height: 220px;
        }
        @include media-breakpoint-down(lg) {
          min-height: 250px;
        }

      }
    }
    .card-body {
      a {
        color: #0b4c8c;
        text-decoration: none;
        text-transform: uppercase;
      }
      .card__location--nb {
        color: #757575;
        font-weight: 1rem;
        font-weight: 400;
      }
      .card__title {
        font-size: 1.125rem;
        line-height: 24px;
        font-weight: 500;
        a {
          text-transform: none;
          color: #222222;
          &:hover {
            color: #0b4c8c;
          }
        }
      }
      .more {
        font-weight: 400;
        font-size: 1rem;
      }
    }
  }
}
.pagination__component {
  ul {
    display: flex;
    flex-direction: row;
    @include media-breakpoint-down(md) {
      flex-direction: row;
      flex-wrap: wrap;
      margin: 10px 0 0 -6px;
      padding: 0;
    }
    @include media-breakpoint-down(sm) {
      flex-direction: row;
      flex-wrap: wrap;
      margin: 10px 0 0 -6px;
      padding: 0;
    }
    li {
      list-style: none;
    }
  }
}
.pagination__current {
  padding: 5px 0px 0px 15px;
}
.active > .page-link,
.page-link.active {
  background-color: #fd8901;
}

.font-size-big {
  font-size: 2.188rem !important;
  @include media-breakpoint-down(xl) {
    font-size: 1.625rem !important;
  }
  @include media-breakpoint-down(lg) {
    font-size: 1.5rem !important;
  }

  @include media-breakpoint-down(sm) {
    font-size: 1.5rem !important;
    line-height: 1.875rem;
  }
}

@media (max-width: 575px) {
  .blog-wrapper {
    width: 50%;
    .card--blog {
      .card__location--nb {
        font-size: 0.875rem;
        font-weight: 400;
      }
      .card__img {
        img {
          width: 100%;
          min-height: 250px;
          object-fit: cover;
          border-radius: 6px;
          overflow: hidden;
        }
      }

      .card-body {
        .card__title {
          font-size: 0.875rem;
          line-height: 1.3125rem;
          font-weight: 500 !important;
        }
        .more {
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.3125rem;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .blog-wrapper {
    width: 100%;
  }
}

.trusted {
  padding: 2rem 0rem 0rem 0rem;
  &__images{
    ul{
      gap: 55px;
      li{
        list-style: none;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 2rem 0rem 0rem 0rem;
  }

  &__row {
    padding-top: 2rem;
    // column-gap: 50px;
    --bs-gutter-x: 4rem;
  }

  &__row > div {
    @include media-breakpoint-down(md) {
      width: 100%;
      flex-direction: row;
    }
  }
}

// The marquee animation for smooth scrolling
// .marquee {
//   &__container {
//     position: relative;
//     width: 100%;
//     overflow: hidden;
//     height: auto;
//   }

//   &__item {
//     display: block;
//     // width: 220px;
//     /* width of your text div */
//     // height: 50px;
//     /* height of your text div */
//     position: absolute;
//     animation: marquee 10s linear infinite; /* change 5s value to your desired speed */

//     &:hover {
//       animation-play-state: paused;
//       cursor: pointer;
//     }
//   }
// }
// @keyframes marquee {
//   0% {
//     left: 100%;
//   }
//   100% {
//     left: -220px; /* same as your text width */
//   }
// }

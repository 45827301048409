.main {
  position: relative;
  z-index: 0;
  margin-top: -129px;

  @include media-breakpoint-down(xl) {
    margin-top: -135px;
  }

  @include media-breakpoint-down(lg) {
    margin-top: -5px;
  }
}

.hero {
  &__background1 {
    @include media-breakpoint-down(sm) {
      height: 100% !important;
      background-position: center;
    }
  }


  &__subtitle {
    display: block;

    @include media-breakpoint-down(lg) {
      font-size: 0.9rem;
    }
  }
}

.carousel-inner {
  height: 100vh;

  @include media-breakpoint-down(xl) {
    height: 100vh;
  }

  @include media-breakpoint-down(lg) {
    height: 100vh;
  }

  @include media-breakpoint-down(md) {
    height: 90vh;
  }

  @include media-breakpoint-down(sm) {
    height: 90vh;
  }
}

.carousel-item {
  height: inherit;
}

// CAROUSEL INDICATORS MODIFICATION
.carousel-indicators [data-bs-target] {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.carousel-indicators .active {
  border: unset !important;
  border-radius: 20px;
  transform: translateY(8px);
  width: 42px;
  height: 14px;
}


// .overlay-banner{
//   position: absolute;
//   border-radius: 10px;
//   top: 0;
//   bottom: 0;
//   left: 0px;
//   right: 10px;
//   height: 100%;
//   width: 100%;
//   display: block;
//   transition: .5s ease;
//   background: linear-gradient(90deg, rgba(36,8,0,1) 0%, rgba(213,29,10,0.8127626050420168) 0%, rgba(0,245,255,0) 100%);
// }

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.6;
  z-index: 5;
  background: linear-gradient(100.83deg, #C9320C 12.99%, rgba(239, 72, 30, 0) 82.55%);
  opacity: 1;
  border-radius: 6px;
}

.overlay-black {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.6;
  z-index: 5;
  background: #000000;
  opacity: 0.35;
  border-radius: 6px;
}



.navbar-nav .active1 a {
  color: orange;
}

.overlay-aboutus {
  position: absolute;
  z-index: 8 !important;
  border-radius: 0px;
  top: 0;
  bottom: 0;
  left: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  opacity: 0.3;
  display: block;
  transition: .5s ease;
  background-color: #000000;
}

.free-consulatation__btn {
  width: 223px !important;

  @include media-breakpoint-down(lg) {
    font-size: 16px;
  }

  @include media-breakpoint-down(sm) {
    width: 161px !important;
    font-size: 14px;
    padding: .5rem;
  }
}

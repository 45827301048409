.contact {
    &__title {
      font-weight: 600;
      font-size: 34px;
      color: #222222;
    }
    @media (max-width: 992px) {
      &__title {
        font-size: 26px;
        line-height: 32px;
      }
    }
    @media (max-width: 576px) {
      &__title {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }

  .content-wrapper {
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin-top: 24px;

    @include media-breakpoint-down(sm) {
      margin: 0.5rem 0.3rem;
    }

    .img-block {
      img {
        width: 100%;
        max-height: 656px;
        height: 100%;
        object-fit: cover;
        border-radius: 12px 0px 0px 12px;
      }
    }

    .contact-form {
      padding: 1.5rem 2rem;
      align-self: center;

      @include media-breakpoint-down(xl) {
        padding: 0.8rem 2rem;
      }

      @include media-breakpoint-down(lg) {
        padding: 2rem;
      }

      .form-container {
        h5 {
          font-size: 1.25rem;
          font-weight: 600;
          padding-bottom: 1rem;

          @include media-breakpoint-down(xl) {
            padding: 0.3rem;
            margin: 0;
          }
        }

        .row {
          margin-bottom: 1.25rem;
        }

        input[type="text"],
        input[type="email"],
        input[type="phone"] {
          background-color: #f3f3f3;
          padding: 12px 24px;
          border: 1px solid #e8e8e8;
          margin-top: 5px;

          @include media-breakpoint-down(xl) {
            padding: 0.5rem;
          }

          &::placeholder {
            color: #747575;
            opacity: 1;
          }

          &:-ms-input-placeholder {
            color: #2c2c2c;
          }

          &::-ms-input-placeholder {
            color: #2c2c2c;
          }
          @media (max-width: 1200px) {
            font-size: 0.8rem;
          }
        }

        textarea {
          background-color: #f3f3f3;
          padding: 12px 24px;
          border: 1px solid #e8e8e8;
          margin-top: 5px;

          &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #747575;
            opacity: 1; /* Firefox */
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #2c2c2c;
          }

          &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #2c2c2c;
          }

          @media (max-width: 1200px) {
            font-size: 0.8rem;
          }
        }
      }
    }
  }

  .contact-details {
    margin: 50px 0px;

    &__list {
      display: flex;
      justify-content: space-evenly;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
      }

      li {
        width: 25%;
        list-style: none;
        text-align: center;

        @include media-breakpoint-down(lg) {
          width: 100%;
        }

        .bi {
          background-color: #fbf1e5;
          padding: 0.8rem 1rem;
          border-radius: 50%;
          color: #fd8901;
          margin-bottom: 15px;
        }

        .text {
          font-weight: 500;
          margin-top: 20px;
          color: #000;
          font-size: 1rem;
          line-height: 28px;
        }
      }
    }
  }

  .map-wrapper {
    margin-bottom: 30px;
    iframe {
      width: 100%;
      height: 380px;
      border-radius: 12px;

      @include media-breakpoint-down(xl){
        height:317px;
      }
    }
  }

  .forms-section {
    &__img-text-section {
      max-width: 375px;
      position: absolute;
      top: 5%;
      background: linear-gradient(
        90deg,
        #0b4c8c 0%,
        rgba(11, 76, 140, 0.33) 100%
      );
      border-radius: 0px 50px 50px 0px;
      padding: 1rem 0.5rem;
      color: #fff;

      h5,
      p {
        margin: 0;
      }
    }

    p {
      font-size: 14px;

      @include media-breakpoint-down(lg) {
        font-size: 13px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 12px;
      }
    }

    &__img-title {
      margin-bottom: 0.3rem !important;

      @include media-breakpoint-down(lg) {
        font-size: 0.95rem;
      }

      @include media-breakpoint-down(md) {
        font-size: 0.9rem;
      }

      @include media-breakpoint-down(sm) {
        font-size: 0.85rem;
      }
    }

    &__title {
      font-size: 1rem;

      @include media-breakpoint-down(xl) {
        font-size: 0.8rem;
      }
    }

    &__img {
      align-self: center;
      border-radius: 12px 0px 0px 12px;

      @include media-breakpoint-down(lg) {
        border-radius: 12px 12px 0 0;
      }
    }
  }


  .contact-us {
    &__row {
      --bs-gutter-x: 3rem;
    }

    .form-control {
      background: #f3f3f3;
      border: 1px solid #e8e8e8;
      border-radius: 6px;
    }

    .form-container {
      margin-top: 2rem;
      padding: 2rem;
      background: #ffffff;
      box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
    }

    .contact-home {
      justify-content: flex-start;
      gap: 2rem;
      margin: 0;

      @include media-breakpoint-down(lg) {
        flex-direction: row;
      }

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }

      li {
        i {
          font-size: 1.2rem;
        }

        a,
        div {
          font-weight: 400;
        }
      }
    }
  }

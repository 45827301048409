.recent-blog {
  &__container {
    margin: 0rem 0;
    @include media-breakpoint-down(lg) {

      margin-top: 3rem;
    }
    h3{
      color: #0B4C8C;
      font-weight: 600;
      @include media-breakpoint-down(lg) {
        font-size: 1.25rem;
        font-weight: 600;
        margin-top: 30px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 1.125rem;
        font-weight: 600;
        margin-top: 30px;
      }
    }
  }

  &__container + &__container {
    margin-top: 3rem;

  }

  &__collection {
    margin-top:1.625rem;
    gap: 1.5rem;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(lg) {
      flex-direction: row;
      gap: 0;
      row-gap: 20px;
    }
  }

  //   EACH BLOG ITEM
  &__item {
    display: flex;
    gap: 0.7rem;

    &-title {
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 1.25rem;
      letter-spacing: 0.25px;
      color: #000000;
      margin-bottom: 0;
      font-family: "Roboto", sans-serif;

      @include media-breakpoint-down(xl) {
        font-size: 1rem;
      }

      @include media-breakpoint-down(lg) {
        font-size: 1rem;
      }
    }

    &-date {
      font-weight: 400;
      font-size: 13px;
      letter-spacing: 0.25px;
      color: #5e5f60;
      line-height: 2px;
    }
  }
}

.career {
  &__title {
    font-weight: 600;
    font-size: 2.125rem;
    color: #222222;
    @include media-breakpoint-down(lg) {
      font-size: 1.75rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 1.5rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 1.25rem;
    }
  }
}

.career-faq {
  &__scontainer {
    @media (max-width: 1498px) {
      max-width: 980px;
    }
  }

  &__ktm {
    flex-direction: column;
  }

  &__pol {
    @include media-breakpoint-down(md) {
      display: flex;
      flex-wrap: wrap;
    }
    .text-custom-blue {
      font-weight: 500;
      color: #222222;
    }
  }

  &__ftime {
    color: #699635;
    font-weight: 500;
    background-color: #bff5b1;
    border-radius: 6px;
    padding: 2px 8px 2px 8px;
    margin-left: 20px !important;
    display: inline-block;

    @include media-breakpoint-down(md) {
      margin-left: 0 !important;
      font-size: 12px !important;
      padding-bottom: 10px;
    }
  }

  &__address {
    color: #747575;
    font-weight: 400;
    font-size: 14px;

    @include media-breakpoint-down(md) {
      font-size: 12px;
    }
  }

  &__ptime {
    display: inline-block;
    color: #fd8901;
    font-weight: 500;
    background-color: #fdecd7;
    border-radius: 6px;
    padding: 2px 8px 2px 8px;
    margin-left: 20px !important;

    @include media-breakpoint-down(md) {
      margin-left: 0 !important;
      font-size: 12px !important;
    }
  }

  &__btn {
    color: white;
    background-color: $primary;
    border: none;
    border-radius: 6px;
    width: 282px;
    height: 43px;
    margin-top: 20px;

    @include media-breakpoint-down(xl) {
      height: 35px;
      width: 150px;
    }
  }

  .accordion-item {
    background-color: #fff;
    color: #808080;
    margin-top: 20px;
    padding: 6px;
    border: 2px solid #ececec !important;
    border-radius: 6px;

    @include media-breakpoint-down(md) {
      padding: 10px !important;
    }

    &__ktm {
      @include media-breakpoint-down(md) {
        font-size: 14px;
      }
    }
    .accordion-body {
      font-size: 14px;
    }
  }

  .accordion-button {
    background-color: #fff;
    padding-left: 10px;


    &:focus {
      box-shadow: none;
    }

    &:not(.collapsed) {
      color: #222 !important;
      background-color: white !important;
      font-weight: 600;
    }

    // &::not(.collapsed)::after {

    //   background-image:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M13.1216 7.488H8.27359V12.456H5.72959V7.488H0.881594V5.184H5.72959V0.215999H8.27359V5.184H13.1216V7.488Z'/></svg>");
    // }
  }
  .accordion-button::after {
    background-image: url("https://cdn.asparksys.com/medias/1662354397005.svg");
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("https://cdn.asparksys.com/medias/1662355012217.svg");
  }
}

.applyjob {
  padding-bottom: 50px;
  @include media-breakpoint-down(md) {
    padding-left: 0 !important;
  }

  &__apply {
    font-weight: 500;
    font-size: 20px;
    color: #222222;
    padding-bottom: 35px;
    @include media-breakpoint-down(md) {
      padding-left: 0 !important;
    }
  }

  &__form {
    margin-top: 30px;
    padding: 30px;
    width: 802px;
    box-shadow: 2px 3px 10px -1px rgba(0, 0, 0, 0.25);
    border-radius: 6px;

    @include media-breakpoint-down(md) {
      padding-left: 1 !important;
      width: 350px;
      width: 400px;
    }

    @include media-breakpoint-down(xl) {
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    @media (max-width: 396px) {
      width: 100%;
    }
  }

  &__queries-form {
    padding-top: 20px;

    @include media-breakpoint-down(md) {
      padding-top: 1px !important;
    }
  }

  &__select_career {
    flex-direction: column;
    width: 100%;

    select {
      width: 100%;
      padding: 0.375rem 0.75rem;
      color: #747575;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: #f3f3f3;
    }
  }

  .form-group {
    margin-bottom: 10px;
    input {
      width: 100%;
      background-color: #f3f3f3;

      &::placeholder {
        color: #747575;
      }
    }

    textarea {
      width: 100%;
      resize: none;
      min-height: 150px;
      background-color: #f3f3f3;

      &::placeholder {
        color: #747575;
      }
    }

    // label {
    // }
  }

  &__upload_form {
    text-align: center;
    padding: 32px 0px;
    border: 1px solid #b7b7b7;
    border-radius: 6px;
    background: #f3f3f3;
    margin-top: 10px;

    #imageName {
      color: green;
    }

    input {
      display: none;
    }

    label {
      cursor: pointer;
      width: 100%;
      height: 100%;
      font-size: 12px;

      p {
        color: #fd8901;
        font-weight: 500;
        font-size: 14px;
        padding-top: 18px;
      }
    }
  }

  &__column {
    padding-right: 0 !important;
  }

  &__submitbtn {
    color: white;
    background-color: $primary;
    border: none;
    border-radius: 6px;
    width: 282px;
    height: 43px;
    margin-top: 20px;

    @media (max-width: 396px) {
      height: 50px !important;
      width: 100% !important;
      font-size: 0.925rem;
    }

    @include media-breakpoint-down(xl) {
      height: 40px;
      width: 200px;
    }
  }
}

.why-us {
  padding: 2rem 0rem;
  position: relative;
  background-image: url(/static/assets/img/about/center-cicle.png);
  background-position: 70% 40%;
  background-repeat: no-repeat;

  @include media-breakpoint-down(xxl) {
    background-position: 71% 40%;
  }

  @include media-breakpoint-down(lg) {
    background-image: none;
  }

  .career-plan__card {
    height: 100%;
  }

  .career-block {
    @include media-breakpoint-down(sm) {
      margin-bottom: 0.9375rem;
    }
  }

  &__gutter-row {
    --bs-gutter-x: 3rem;
  }

  &__top-section {
    // position: relative;
    &::before {
      background-image: url(/static/assets/img/about/half-ec.png);
      background-repeat: no-repeat;
      content: "";
      display: inline-block;
      height: 230px;
      left: -20px;
      position: absolute;
      top: -50px;
      width: 140px;
      z-index: -1;

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    &::after {
      content: "";
      background-image: url(/static/assets/img/about/blue-dot.png);
      background-repeat: no-repeat;
      display: inline-block;
      height: 100px;
      width: 220px;
      position: absolute;
      right: 25px;
      bottom: -5px;
      z-index: -1;

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }
}

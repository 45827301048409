.about {
  &__banner {
    position: relative;

    h3 {
      margin: 0;
      height: 100%;
      // width: 100%;
      position: absolute;
      left: calc((100% / 2) - 140px);
      top: calc((100% / 2) - 48px);
      color: #fff;
      font-weight: 600;
      font-size: 4rem;
      line-height: 96px;
      letter-spacing: 0.25px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      z-index: 9;

      @include media-breakpoint-down(xl) {
        font-size: 3rem;
        left: calc((100% / 2) - 124px);
        top: calc((100% / 2) - 34px);
      }

      @include media-breakpoint-down(lg) {
        font-size: 2rem;
        left: calc((100% / 2) - 90px);
        top: calc((100% / 2) - 40px);
      }

      @include media-breakpoint-down(md) {
        font-size: 1.875rem;
        left: calc((100% / 2) - 75px);
        top: calc((100% / 2) - 45px);
      }

      @include media-breakpoint-down(sm) {
        font-size: 1.875rem;
        left: calc((100% / 2) - 75px);
        top: calc((100% / 2) - 45px);
      }
    }
  }

  &__subtitle {
    // font-size: 16px;
    // color: #808080;
    // line-height: 30px;
    text-align: justify;
    margin-top: 0.5rem;

    @include media-breakpoint-down(sm) {
      line-height: 24px;
    }
  }

  &__mission {
    .title__secondary {
      text-transform: none;
    }

    p {
      // font-size: 16px;
      line-height: 30px;
      // color: #808080;
      letter-spacing: 0.8px;

      @include media-breakpoint-down(sm) {
        line-height: 24px !important;
      }
    }
  }
}

.md {
  position: relative;
  padding: 4rem 0;

  &::before {
    background-image: url(/static/assets/img/about/eclipse.png);
    background-repeat: no-repeat;
    content: "";
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 50px;
    height: 265px;
    width: 265px;
    z-index: -1;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &::after {
    background-image: url(/static/assets/img/about/red-dot.png);
    background-repeat: no-repeat;
    content: "";
    display: inline-block;
    height: 100px;
    width: 240px;
    position: absolute;
    right: 240px;
    top: 100px;
    z-index: -1;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &__img {
    border-radius: 50%;
    height: 70px;
    width: 70px;
  }
}

.career-plan {
  padding: 2rem;

  &__top-row {
    align-items: stretch;
  }

  &__card {
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    border: none;
    margin-bottom: 10px;
    height: 100%;
  }
}

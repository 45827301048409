.slide {
  &__img {
    width: 100%;
    // max-height: 420px;
    // height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
}

.slide-indicator {
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  justify-content: flex-start;
  max-height: 460px;
  overflow-y: scroll!important;
  gap: 0.5rem;
  overflow-y: clip;
  #scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  #scroll::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }
  #scroll::-webkit-scrollbar-thumb {
    background-color: #000000;
    border: 2px solid #555555;
  }

  @include media-breakpoint-down(xl) {
    max-height: 326px;
    overflow-y: scroll;
    .slide-indicator {
      max-height: 380px;
      overflow-y: scroll;
      gap: 0rem;
      #scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
      }
      #scroll::-webkit-scrollbar {
        width: 10px;
        background-color: #f5f5f5;
      }
      #scroll::-webkit-scrollbar-thumb {
        background-color: #000000;
        border: 2px solid #555555;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    max-height: 220px;
    overflow-y: scroll;
    .slide-indicator {
      max-height: 280px!important;
      overflow-y: scroll;
      gap: 0rem;
      #scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
      }
      #scroll::-webkit-scrollbar {
        width: 10px;
        background-color: #f5f5f5;
      }
      #scroll::-webkit-scrollbar-thumb {
        background-color: #000000;
        border: 2px solid #555555;
      }
    }
  }


  button {
    height: 65px !important;
    width: 100px !important ;
    opacity: 1 !important;

    img {
      width: 100px;
      height: 65px;
      border-radius: 4px;
    }
  }
}

.top-5 {
  top: -10%;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}

.left-20 {
  left: -20px;

  @include media-breakpoint-down(sm) {
    left: 0;
  }
}

.right-20 {
  right: -20px;

  @include media-breakpoint-down(sm) {
    right: 0;
  }
}

.carousel-indicators .active {
  border: 2px solid $primary;
  border-radius: 6px;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
.carousel-control-prev {
  width: 25%;
  z-index: 1;

  @include media-breakpoint-down(xl) {
    left: 25px;
    img {
      width: 35px;
      height: auto;
    }
  }

  @include media-breakpoint-down(lg) {
    left: 55px;
    top: -75px;
    img {
      width: 25px;
      height: auto;
    }
  }
  @include media-breakpoint-down(md) {
    left: -40px;
    top: -75px;
    img {
      width: 25px;
      height: auto;
    }
  }
  @include media-breakpoint-down(sm) {
    left: -20px;
    top: -75px;
    img {
      width: 25px;
      height: auto;
    }
  }
}

.carousel-control-next {
  width: 7%;
  right: -45px;
  z-index: 1;
  @include media-breakpoint-down(xl) {
    right: -25px;
    img {
      width: 35px;
      height: auto;
    }
  }

  @include media-breakpoint-down(lg) {
    right: -20px;
    top: -75px;
    img {
      width: 25px;
      height: auto;
    }
  }
  @include media-breakpoint-down(md) {
    right: 10px;
    top: -75px;
    img {
      width: 25px;
      height: auto;
    }
  }
  @include media-breakpoint-down(sm) {
    right: 0px;
    top: -75px;
    img {
      width: 25px;
      height: auto;
    }
  }
}

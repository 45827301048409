.newsletter {
  padding: 2rem;
  @include media-breakpoint-down(lg) {
    padding: 2rem 0rem;
  }
  &__heading {
    max-width: 575px;
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }

  form {
    width: 60%;

    @include media-breakpoint-down(lg) {
      width: 80%;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  &__submit-container {
    width: 100%;
    position: relative;

    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }

  &__row > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__input-submit {
    background: #f4f4f4;
    border-radius: 40px;
    color: #808080;
    border: 0;
    padding: 1.3rem 2.2rem;
    width: 100%;

    @include media-breakpoint-down(xl) {
      font-size: 0.9rem;
    }
  }
}

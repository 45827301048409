.benchmark {
  padding: 2rem 0;

  @include media-breakpoint-down(sm) {
    padding: 2rem 0rem;
  }

  &__location {
    position: absolute;
    left: 10%;
    bottom: 5%;
    color: #fff;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.1em;
  }

  &__heading {
    padding: 2rem 0 0;

    @include media-breakpoint-down(xl) {
      padding: 0 0 0;
    }
  }

  &__card-img {
    max-width: 100%;
    width: 100%;
    max-height: 350px;
    height: 350px;
    border-radius: 4px;
    object-fit: cover;

    @include media-breakpoint-down(xxl) {
      max-width: 250px;
      width: 250px;
    }

    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }

  &__subtitle {
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    margin: 2rem 0;

    @include media-breakpoint-down(xxl) {
      font-size: 16px;
      margin: 1rem 0;
    }

    @include media-breakpoint-down(lg) {
      font-size: 14px;
      margin: 0;
    }
  }

  .slick-slide {
    margin: 0 1.2rem;
  }


  .margin-1 {
    margin-top: 1.5rem;

    @include media-breakpoint-down(xl) {
      margin-top: 0.85rem;
    }

    @include media-breakpoint-down(lg) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}

.benchmark {
  .slider3 {

    .slick-track {
      display: flex;

      @media (min-width: 992px) and (max-width: 1200px) {
        column-gap: 20px;
      }

      @include media-breakpoint-down(lg) {
        column-gap: 40px;
      }

      @include media-breakpoint-down(md) {
        column-gap: inherit;
      }
    }
  }
}

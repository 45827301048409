.nav-link {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #fff;

  @include media-breakpoint-down(xl) {
    font-size: 12px;
  }

  &:hover {
    color: $warning;
  }
}

.navbar-nav .nav-link.active {
  color: $warning;
}

.header {
  // position: relative;
  // z-index: 5;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.background-medium {
  @include media-breakpoint-down(lg) {
    background-color: $primary;
  }
}
.btn-mr{
  margin-right: 5px;

}
.accordion-background {
  font-size: 12px;
}

.accordion-body {
  padding: 0;
}

.accordion-button::after {
  // margin: 0 0.5rem;
}

.accordion__list {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  font-size: 12px;

  a {
    color: #fff;
  }

  li {
    color: #fff;
  }
}

.desktop-only {
  @include media-breakpoint-up(lg) {
    display: block;
  }

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.mobile-only {
  @include media-breakpoint-up(lg) {
    display: none;
  }

  @include media-breakpoint-down(lg) {
    display: block;
  }
}

.header-two-link {
  color: #000;

  @include media-breakpoint-down(lg) {
    color: #fff;
  }

  &:focus {
    color: #fd8901;
  }
}

.navs-two {
  color: #000 !important;
}

.dropdown:hover {
  .dropdown-menu {
    display: block;
  }
}

.navbar-control {
  height: 45px;
  width: 115px;
}

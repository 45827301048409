.footer {
  padding: 2.5rem 0 0 0;
  border-top: 1px solid #dfdfe0;

  @include media-breakpoint-down(sm) {
    padding: 2.5rem 0rem;
  }

  &__row {
    @include media-breakpoint-down(lg) {
      --bs-gutter-y: 3rem;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    color: #808080;
    a {
      color: #808080;
      &:hover {
        color: #0b4c8c;
      }
    }
  }

  &__social {
    color: #0b4c8c;

    a {
      display: inline-block;
      background-color: #e3ebfd;
      border-radius: 50%;
      font-size: 16.7px;
      margin: 0 0.2rem;
      padding: 0.8rem 1rem;
      width: auto;
      height: auto;
      color: #0b4c8c;
      &:hover {
        background-color: #808080;
        color: #fff;
      }
      &:first-child {
        margin: 0;
      }
    }
  }

  &__subtitle {
    max-width: 310px;
    color: #808080;
    margin-top: 1.2rem;
    font-size: 16px;
    line-height: 30px;
  }

  &__copyright {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0 2rem;
    margin-top: 2rem;
    border-top: 1px solid #d9dbe9;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      padding: 1rem 0rem 0rem 1rem;
    }
  }

  &__lists {
    display: flex;
    row-gap: 10px;
    flex-direction: column;

    &-title {
      margin-bottom: 1.2rem;
    }
  }

  &__contact {
    display: flex;
    column-gap: 10px;
    align-items: center;

    a{
      word-break: break-all;
    }
  }
}

.f-500 {
  font-weight: 500;
}

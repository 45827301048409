.form {
  &__search {
    width: 26%;

    @include media-breakpoint-down(xxl) {
      width: 30%;
    }

    @include media-breakpoint-down(xl) {
      width: 33%;
    }

    @include media-breakpoint-down(lg) {
      width: 100%;
      margin: 1rem 0;
      border: 1px solid #fff;
      border-radius: 6px;
    }
  }
}

.nav {
  &__search {
    border: 2px solid transparent;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.015em;
    background: transparent;

    @include media-breakpoint-down(xl) {
      font-size: 10px;
    }

    &:focus {
      background: transparent;
      border: 2px solid #fff;
      box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
      color: #fff;
    }

    &::placeholder {
      color: #fff;
    }
  }

  &__search-two {
    border: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.015em;
    background: transparent;

    @include media-breakpoint-down(xl) {
      font-size: 10px;
    }

    &:focus {
      background: transparent;
      border: 2px solid #000;
      box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
      color: #000;
    }

    &::placeholder {
      color: #000;

      @include media-breakpoint-down(lg) {
        color: #fff;
      }
    }
  }
}

.btn {
  &__search-icon {
    position: absolute;
    top: 10%;
    right: 0%;
    padding: 0.2rem;

    &:focus {
      top: 5%;
      right: 2%;
    }

    i {
      color: #fff;
    }
  }

  &__search-icon-two {
    position: absolute;
    top: 1%;
    right: 2%;
    padding: 0.2rem;

    &:focus {
      top: 5%;
      right: 2%;
      border: none;
    }

    &:hover {
      border: none;
    }

    i {
      color: #000;

      @include media-breakpoint-down(lg) {
        color: #fff;
      }
    }
  }
}

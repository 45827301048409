.latest-blog {
  .slick-slide {
    margin: 0 0.5rem;
  }

  .slick-track{
    margin-left: inherit;
    margin-right: inherit;
  }

  .slick-list {
    padding: 1rem 0;
  }

  .card {
    box-shadow: none;
    border: none;
  }

  &__row {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    .view-more {
      @include media-breakpoint-down(sm) {
        text-align: left;
      }
    }
  }
}

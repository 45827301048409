.navs {
  color: #fff;
  padding: 0.8rem 0;
  font-size: 12px;
  border-bottom: 1px solid #bebebe;

  @include media-breakpoint-down(lg) {
    display: none;
  }

  &__left {
    display: flex;
  }

  &__number {
    display: flex;
    border-right: 1px solid #bebebe;
    a{
      color: #fff;
      &:hover{
        text-decoration: underline;
      }
    }
  }

  &__right {
    display: flex;
  }

  &__email {
    margin-right: 0.5rem;
    border-right: 1px solid #bebebe;
    a{
      color: #fff;
      &:hover{
        text-decoration: underline;
      }
    }
  }

  &__social {
    display: flex;
    gap: 0.5rem;
    a{
      color: #fff;
    }
    &:hover{
      color: #fff;
    }
  }
}

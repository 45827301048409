.blog-detail {
  &__title {
    font-size: 1.5rem;
    @include media-breakpoint-down(lg) {
      font-size: 1.25em;
      font-weight: 500;
    }

    @include media-breakpoint-down(md) {
      font-size: 1.125rem;
      font-weight: 500;
    }

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
      font-weight: 500;
      padding-bottom: 5px;
    }
  }

  &__img {
    img {
      width: 100%;
    }
  }

  //   Text section starts here
  &__text {
    margin-top: 1rem;
  }

  &__text-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 2px;
    letter-spacing: 0.25px;
    color: #5e5f60;
    margin: 1.625rem 0rem 1.375rem;

    @include media-breakpoint-down(md) {
      font-size: 0.875rem;
    }

    @include media-breakpoint-down(sm) {
      font-size: 0.85rem;
    }
  }

  &__text-para {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    letter-spacing: 0.014em;
    color: #121212;
    @include media-breakpoint-down(xl) {
      font-size: 0.875rem;
    }

    @include media-breakpoint-down(sm) {
      font-size: 0.85rem;
    }
  }

  &__icons {
    display: flex;
    justify-content: space-between;

    &-left {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      span {
        @include media-breakpoint-down(sm) {
          font-size: 0.85rem;
        }

        img {
          margin-right: 0.5rem;
        }
      }
    }

    &-right {
      span {
        margin-right: 0.5rem;

        @include media-breakpoint-down(sm) {
          font-size: 0.85rem;
        }
      }

      img + img {
        margin: 0 0.2rem;
      }

      img {
        @include media-breakpoint-down(sm) {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
